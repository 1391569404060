import { render, staticRenderFns } from "./ImageUploader.vue?vue&type=template&id=4cae9c24&lang=pug"
import script from "./ImageUploader.vue?vue&type=script&lang=ts"
export * from "./ImageUploader.vue?vue&type=script&lang=ts"
import style0 from "./ImageUploader.vue?vue&type=style&index=0&id=4cae9c24&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCardText,VChip,VFileInput,VIcon,VImg})
